import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import trending_icon from "../Image/TrendingIcon.png";
import Footer from "./Footer";
import tick_blue from "../Image/tick_blue.png";
import tick_gold from "../Image/tick_gold.png";
import Paper from "@mui/material/Paper";
import Pie_chart from "./Pie_chart";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Sparkline_chart from "./Sparkline_chart";
import Navbar from "./Navbar";
import axios from "axios";
import { Toaster } from "react-hot-toast";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import timer from "../Image/timer.png";
import { Tooltip } from "antd";

function Home({ url, Dissconnect, Metamask, WalletC }) {
  // Define state variables
  const [crypt, setCrypt] = useState([]);
  const [crypto_recently, setcrypto_recentlys] = useState([]);
  const [cryptoPrices, setCryptoPrices] = useState({});
  const [marketPrices, setmarketPrices] = useState({});
  const [volume, setVolumes] = useState({});
  const [crypto24hchanges, setCrypto24hchangess] = useState({});
  const [h24_change, seth24_changes] = useState({});
  const [visible, setvisibles] = useState(false);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [visibleRows, setVisibleRows] = useState(10); // Number of rows initially visible
  const tableRef = useRef(null);
  const [marketCapSortOrder, setMarketCapSortOrder] = useState(true); // Initialize with 'desc' for descending order
  const [priceSortOrder, setPriceSortOrder] = useState(true); // Initialize with 'asc' for ascending order
  const [h24ChangeSortOrder, setH24ChangeSortOrder] = useState(true);
  const [volume24hChangeSortOrder, setvolume24hChangeSortOrder] =
    useState(true);
  const [circulating_supply, setcirculating_supplys] = useState({});

  const [circulatingSupplySortOrder, setCirculatingSupplySortOrder] =
    useState(true);
  const [volumecrypt, setVolumecrypt] = useState([]);
  const [sortCriterion, setSortCriterion] = useState("marketPrices"); // Default sorting criterion
  const [loading, setLoading] = useState(true);

  // Create an IntersectionObserver to load more rows when the last row becomes visible
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const lastRow = entries[0];
        if (lastRow.isIntersecting) {
          // Load more rows when the last row becomes visible
          setVisibleRows((prevVisibleRows) => prevVisibleRows + 10); // You can adjust the number of rows to load
        }
      },
      { root: null, rootMargin: "0px", threshold: 0.1 },
    );

    if (tableRef.current) {
      observer.observe(tableRef.current);
    }

    return () => {
      if (tableRef.current) {
        observer.unobserve(tableRef.current);
      }
    };
  }, []);

  // Fetch crypto data when the component mounts
  useEffect(() => {
    fetchCryptData();
    fetchCryptDatassy();
    fetchRUSDData();
    fetchGoldPrice();
  }, []);

  // Fetch crypto data from an API
  const fetchCryptData = async () => {
    try {
      const response = await axios.get(`${url}/get/crypto`);
      // const sortedData = response.data.sort((a, b) => b.market_cap - a.market_cap);
      setcrypto_recentlys(response.data);
      setCrypt(response.data);
      setVolumecrypt(response.data);
    } catch (error) {
      console.error("Error fetching crypto data:", error);
    }
  };

  const [gcs__volume, setgcs__volumes] = useState();
  const [gcs__price, setgcs__prices] = useState();
  const [gcs__low, setgcs__lows] = useState();
  const [gcs__high, setgcs__highs] = useState();
  const [gcs__change, setgcs__changes] = useState();

  const fetchCryptDatassy = async () => {
    try {
      const response = await axios.post(
        `https://sapi.gcex.lt/v1/market/tickers`,
      );

      // Access the "GCS/USDT" data
      const gcsUsdtData = response.data.data["GCS/USDT"];
      setgcs__volumes(gcsUsdtData.volume);
      setgcs__prices(gcsUsdtData.close);
      setgcs__lows(gcsUsdtData.low);
      setgcs__highs(gcsUsdtData.high);
      setgcs__changes(gcsUsdtData.change);
    } catch (error) {
      console.error("Error fetching crypto data:", error);
    }
  };

  const [rusd_vol, setrusd_vol] = useState();
  const [rusd_price, setrusd_price] = useState();
  const [rusd_24HChange, setrusd_24HChange] = useState();
  const [rusd_mcap, setrusd_mcap] = useState();
  const [rusd_supply, setrusd_supply] = useState();

  const fetchRUSDData = async () => {
    try {
      setLoading(true);
      // const config = {
      //   headers: {
      //     accept: "application/json",
      //     "x-api-key": "jSy1rxyOCr5lauqYNhbfU6S64F1N7cU73f9UoiQI",
      //   },
      // };

      // Fetch price data
      const priceResponse = await axios.get(
        "https://stupid-heavily-spears-montgomery.trycloudflare.com/api/rusd/price",
        // config,
      );

      const tokenInfoResponse = await axios.get(
        "https://stupid-heavily-spears-montgomery.trycloudflare.com/api/rusd",
        // config,
      );

      console.log("API Response:", priceResponse.data); // Debug log
      console.log("token Info:", tokenInfoResponse.data);

      // Extract required data from price response
      const priceData = priceResponse.data.data;
      const tokenInfo = tokenInfoResponse.data.data;
      console.log("Price Data:", priceData); // Debug log
      console.log("Price Value:", priceData.price); // Debug log

      setrusd_price(priceData.price);
      setrusd_24HChange(priceData.variation24h);
      setrusd_vol(priceData.volume24h);
      setrusd_supply(tokenInfo.totalSupply);
      setrusd_mcap(tokenInfo.fdv);

      // Log after setting state
      console.log("State after setting:", rusd_price);
    } catch (error) {
      console.error("Error fetching RUSD data:", error);
    } finally {
      setLoading(false);
    }
  };

  const [gcs_prices, setgcs_pricess] = useState(null);
  const [xaus_prices, setxaus_pricess] = useState(null);
  const [xaus_24HChange, setxaus_24HChange] = useState(null);

  const fetchGoldPrice = async () => {
    try {
      // Check if we have a stored price and timestamp
      const storedData = localStorage.getItem("goldPriceData");
      const currentTime = Date.now();

      if (storedData) {
        const { price, price24hChange, timestamp } = JSON.parse(storedData);

        // Check if 24 hours have passed since last fetch
        const timeDiff = currentTime - timestamp;
        const hoursPassed = timeDiff / (1000 * 60 * 60);

        // If less than 24 hours, use stored price
        if (hoursPassed < 24) {
          setxaus_pricess((price * 0.425) / 31.1035);
          setxaus_24HChange(price24hChange);
          return;
        }
      }

      // Fetch new price if no stored data or more than 24 hours passed
      const response = await axios.get("https://www.goldapi.io/api/XAU/USD", {
        headers: {
          "x-access-token": "goldapi-2vjrmsm5ywkl7l-io",
        },
      });

      const newPrice = response.data.prev_close_price;
      const price24hChange = response.data.chp;

      console.log("GOLD API Response:", newPrice, price24hChange);

      // Store new price and timestamp
      const newData = {
        price: newPrice,
        price24hChange: price24hChange,
        timestamp: currentTime,
      };

      localStorage.setItem("goldPriceData", JSON.stringify(newData));
      setxaus_pricess((newPrice * 0.425) / 31.1035);
      setxaus_24HChange(price24hChange);
    } catch (error) {
      console.error("Error fetching gold price:", error);

      // Use stored data as fallback if available
      const storedData = localStorage.getItem("goldPriceData");
      if (storedData) {
        const { price, price24hChange } = JSON.parse(storedData); // Added price24hChange here
        setxaus_pricess((price * 0.425) / 31.1035);
        setxaus_24HChange(price24hChange);
      }
    }
  };

  useEffect(() => {
    const fetchDatass = async () => {
      try {
        const response = await axios.get(
          "https://api-dex.gcex.lt/gcstousd/live",
        );
        // const responseData = response.data.data;
        console.log("ef8454", response.data.GCS.usd);

        setgcs_pricess(response.data.GCS.usd);
      } catch (error) {
        console.error("Error fetching crypto data:", error);
      }
    };

    fetchDatass();
  }, []);

  // Helper function to convert a string to lowercase
  const lower_case = (value) => {
    return value.toLowerCase();
  };

  // Sort the crypt array by market cap in ascending order

  const getMarketCapDifference = (a, b) => {
    const priceA = marketPrices[a.project_api_name];
    const priceB = marketPrices[b.project_api_name];

    if (priceA === undefined && priceB === undefined) return 0;
    if (priceA === undefined) return 1;
    if (priceB === undefined) return -1;

    return marketCapSortOrder ? priceB - priceA : priceA - priceB;
  };

  const getPriceDifference = (a, b) => {
    const priceA = cryptoPrices[a.project_api_name];
    const priceB = cryptoPrices[b.project_api_name];

    if (priceA === undefined && priceB === undefined) return 0;
    if (priceA === undefined) return 1;
    if (priceB === undefined) return -1;

    return priceSortOrder ? priceB - priceA : priceA - priceB;
  };

  const get24hChangeDifference = (a, b) => {
    const priceA = crypto24hchanges[a.project_api_name];
    const priceB = crypto24hchanges[b.project_api_name];

    if (priceA === undefined && priceB === undefined) return 0;
    if (priceA === undefined) return 1;
    if (priceB === undefined) return -1;

    return h24ChangeSortOrder ? priceB - priceA : priceA - priceB;
  };

  const getvolume24hChangeDifference = (a, b) => {
    const priceA = volume[a.project_api_name];
    const priceB = volume[b.project_api_name];

    if (priceA === undefined && priceB === undefined) return 0;
    if (priceA === undefined) return 1;
    if (priceB === undefined) return -1;

    return volume24hChangeSortOrder ? priceB - priceA : priceA - priceB;
  };

  const circulate_supplys = (a, b) => {
    const priceA = circulating_supply[a.project_api_name];
    const priceB = circulating_supply[b.project_api_name];

    if (priceA === undefined && priceB === undefined) return 0;
    if (priceA === undefined) return 1;
    if (priceB === undefined) return -1;

    return circulatingSupplySortOrder ? priceB - priceA : priceA - priceB;
  };

  const sortedCrypt = [...crypt].sort((a, b) => {
    switch (sortCriterion) {
      case "price":
        return getPriceDifference(a, b);
      case "h24Change":
        return get24hChangeDifference(a, b);
      case "volumeh24Change":
        return getvolume24hChangeDifference(a, b);
      case "circulate_supply":
        return circulate_supplys(a, b);
      default:
        return getMarketCapDifference(a, b);
    }
  });

  const toggleMarketCapSort = () => {
    setSortCriterion("marketCap");
  };

  const togglePriceSort = () => {
    setSortCriterion("price");
  };

  const toggle24hSort = () => {
    setSortCriterion("h24Change");
  };
  const togglevolume24hSort = () => {
    setSortCriterion("volumeh24Change");
  };
  const toggleCirculatingSupplySort = () => {
    setSortCriterion("circulate_supply");
  };

  const sortedvolumeCrypt = [...volumecrypt].sort((a, b) =>
    a.market_cap > b.market_cap ? 1 : -1,
  );

  // Fetch crypto prices and related data
  const fetchCryptoPrice = async (projectName, api, parameter) => {
    try {
      const response = await axios.get(api);
      const usda = response.data[projectName].usd;
      const usd_24h_change = response.data[projectName].usd_24h_change;
      const usd_market_cap = response.data[projectName].usd_market_cap;
      const usd_24h_vol = response.data[projectName].usd_24h_vol;

      // Update state with fetched data
      setCryptoPrices((prevPrices) => ({
        ...prevPrices,
        [projectName]: usda,
      }));
      setCrypto24hchangess((prevPrices) => ({
        ...prevPrices,
        [projectName]: usd_24h_change,
      }));
      setmarketPrices((prevPrices) => ({
        ...prevPrices,
        [projectName]: usd_market_cap,
      }));
      setVolumes((prevPrices) => ({
        ...prevPrices,
        [projectName]: usd_24h_vol,
      }));
      setcirculating_supplys((prevPrices) => ({
        ...prevPrices,
        [projectName]: usd_market_cap / usda,
      }));
    } catch (error) {
      console.error("Error fetching crypto price:", error);
    }
  };

  sortedvolumeCrypt.sort((a, b) => {
    const priceA = crypto24hchanges[a.project_api_name];
    const priceB = crypto24hchanges[b.project_api_name];
    if (priceA === undefined && priceB === undefined) return 0;
    if (priceA === undefined) return 1;
    if (priceB === undefined) return -1;

    return priceB - priceA; // Sort in descending order
  });

  // Fetch crypto prices when sortedCrypt updates
  useEffect(() => {
    sortedCrypt
      .sort(
        (a, b) =>
          marketPrices[b.project_api_name] - marketPrices[a.project_api_name],
      )
      .filter((res) => res.status === "Approved")
      .forEach((res) =>
        fetchCryptoPrice(res.project_api_name, res.api_live_price, "usd"),
      );
  }, [sortedCrypt.length, sortCriterion]);

  const togglecirculatingsupply = () => {
    const newSortOrder = volume24hChangeSortOrder === "asc" ? "desc" : "asc";
    setvolume24hChangeSortOrder(newSortOrder);

    // Sort the sortedCrypt array based on the cryptoPrices property
    sortedCrypt.sort((a, b) => {
      const priceA = volume[a.project_api_name];
      const priceB = volume[b.project_api_name];

      if (priceA === undefined && priceB === undefined) return 0;
      if (priceA === undefined) return 1;
      if (priceB === undefined) return -1;
      return newSortOrder === "asc" ? priceA - priceB : priceB - priceA;
    });

    // Force re-render by updating a state variable to reflect the sorted order
    setCrypt([...sortedCrypt]);
  };

  // const toggleCirculatingSupplySort = () => {
  //   setCirculatingSupplySortOrder((prevSortOrder) =>
  //     prevSortOrder === "asc" ? "desc" : "asc"
  //   );
  //   sortedCrypt.sort((a, b) => {
  //     const circulatingSupplyA = a.circulating_supply || 0;
  //     const circulatingSupplyB = b.circulating_supply || 0;

  //     if (circulatingSupplySortOrder === "asc") {
  //       return circulatingSupplyA - circulatingSupplyB;
  //     } else {
  //       return circulatingSupplyB - circulatingSupplyA;
  //     }
  //   });

  //   // Force re-render by updating a state variable to reflect the sorted order
  //   setCrypt([...sortedCrypt]);
  // };

  // Sort the data based on the circulating supply
  const sortedCryptData = [...sortedCrypt].sort((a, b) => {
    const circulatingSupplyA = a.circulating_supply || 0;
    const circulatingSupplyB = b.circulating_supply || 0;

    if (circulatingSupplySortOrder === "asc") {
      return circulatingSupplyA - circulatingSupplyB;
    } else {
      return circulatingSupplyB - circulatingSupplyA;
    }
  });

  const fetch_tommorow_price = async () => {
    try {
      const response = await axios.get(
        `https://api-dex.gcex.lt/xaustousd/dayhistory?days=30`,
      );
      // const sortedData = response.data.sort((a, b) => b.market_cap - a.market_cap);
      setcrypto_recentlys(response.data);
      setCrypt(response.data);
      setVolumecrypt(response.data);
    } catch (error) {
      console.error("Error fetching crypto data:", error);
    }
  };

  const [randomNumberxaus, setRandomNumber] = useState(0);

  const [randomNumbergcs, setRandomNumbergcs] = useState(0);
  // Function to generate a random number in the specified range
  const generateRandomNumber = () => {
    const min = 130000;
    const max = 250000;
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
    const randomNumgcs =
      Math.floor(Math.random() * (500000 - 200000 + 1)) + 200000;
    setRandomNumber(randomNum);
    setRandomNumbergcs(randomNumgcs);
  };

  // Generate the initial random number when the component mounts
  useEffect(() => {
    generateRandomNumber();
  }, []);

  const [h24_xaus, set24hpercentagexaus] = useState(null);
  const [h24_gcs, set24hpercentagegcs] = useState(null);

  useEffect(() => {
    // Define the API endpoint URL
    const apiUrl = "https://api-dex.gcex.lt/xaustousd/dayhistory?days=1";
    const apiUrl_gcs = "https://api-dex.gcex.lt/gcstousd/dayhistory?days=1";

    // Fetch data from the API
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        // Check if the "prices" array exists in the response
        if (data.prices && data.prices.length > 0) {
          // Set the price value to the first element of the "prices" array
          set24hpercentagexaus(data.prices[0][1]);
        } else {
          // Handle the case where "prices" is empty or missing
          console.error("No prices data found in the API response");
        }
      });
    fetch(apiUrl_gcs)
      .then((response) => response.json())
      .then((data) => {
        // Check if the "prices" array exists in the response
        if (data.prices && data.prices.length > 0) {
          // Set the price value to the first element of the "prices" array
          set24hpercentagegcs(data.prices[0][1]);
        } else {
          // Handle the case where "prices" is empty or missing
          console.error("No prices data found in the API response");
        }
      })
      .catch((error) => {
        // Handle any errors that occur during the fetch
        console.error("Error fetching data from the API:", error);
      });
  }, []);

  const [highlight, sethighlight] = useState("light-theme");
  const [base, setbase] = useState("light-theme");
  const [theme, setTheme] = useState("");

  useLayoutEffect(() => {
    const data = window.localStorage.getItem("theme");
    if (data === "dark-theme") {
      sethighlight("#444");
      setbase("#202020");
      setTheme("dark");
    } else {
      sethighlight("#d1d1d1");
      setbase("#efecec");
      setTheme("light");
    }
  }, []);

  useEffect(() => {
    circulate_supply_maal();
  }, []);

  const [circ_supply_maal, setcirc_supply_maaals] = useState(2161000000.0);

  const circulate_supply_maal = async () => {
    try {
      const response = await axios.post(`${url}/api/circ__supply_maal`);
      console.log("respond786", response.data.circulating_supply);
      setcirc_supply_maaals(response.data.circulating_supply);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <SkeletonTheme baseColor={base} highlightColor={highlight}>
        <Navbar
          Dissconnect={Dissconnect}
          Metamask={Metamask}
          WalletC={WalletC}
        />
        <div className="container-kws">
          <div className="m-b-3">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-4 m-y-1">
                <div className=" box_shadow p-x-1_5 p-y-0_5 b-r-10 h-100">
                  <div className="d-f a-i-c j-c-s-b m-t-1 m-b-2 ">
                    <div className="f-w-600 f-s-1_25">
                      <img src={trending_icon} className="w-1_5  " />
                      &nbsp; Trending
                    </div>
                  </div>
                  {sortedvolumeCrypt
                    .slice() // Create a copy of the array to avoid mutating the original
                    .filter((res) => res.status === "Approved") // Filter only "Approved" items
                    .slice(0, 5) // Take the top 5 items
                    .map((res, index) => (
                      <div className="d-f a-i-c j-c-s-b m-y-1" key={index}>
                        <div className="">
                          <a href={`/currencies/${res.project_api_name}`}>
                            <img
                              src={res.link_logo}
                              className="w-2 h-2 b-r-50"
                            />
                            &nbsp; {res.project_name} &nbsp;
                            <span className="g_clr f-s-0_8 m-l-0_5">
                              {res.project_ticker_symbol}
                            </span>
                            &nbsp; &nbsp;
                            <Tooltip
                              title={`${
                                res.tick == "gold"
                                  ? "Sharia Compliant"
                                  : "Verified"
                              }`}
                            >
                              <span>
                                {res.tick ? (
                                  <button className="b-c-t b-n ">
                                    {res.tick == "gold" ? (
                                      <img src={tick_gold} className="w-1_5" />
                                    ) : (
                                      <img src={tick_blue} className="w-1_5" />
                                    )}
                                  </button>
                                ) : (
                                  ""
                                )}
                              </span>
                            </Tooltip>
                          </a>
                        </div>
                        {res.project_name == "XAUS" ? (
                          <>
                            {((parseFloat(xaus_prices) - h24_xaus) / h24_xaus) *
                              100 >=
                            0 ? (
                              <div className="green_clr">
                                <MdArrowDropUp className="b-c-t green_f_clr" />{" "}
                                &nbsp;
                                {(
                                  ((parseFloat(xaus_prices) - h24_xaus) /
                                    h24_xaus) *
                                  100
                                ).toFixed(2)}
                                %
                              </div>
                            ) : (
                              <div className="red_clr">
                                <MdArrowDropDown className="b-c-t red_f_clr" />{" "}
                                &nbsp;
                                {(
                                  ((parseFloat(xaus_prices) - h24_xaus) /
                                    h24_xaus) *
                                  100
                                ).toFixed(2)}
                                %
                              </div>
                            )}
                          </>
                        ) : res.project_name == "GCS" ? (
                          <>
                            {parseFloat(gcs__change) >= 0 ? (
                              <div className="green_clr">
                                <MdArrowDropUp className="b-c-t green_f_clr" />{" "}
                                &nbsp;
                                {parseFloat(gcs__change).toFixed(2)} %
                              </div>
                            ) : (
                              <div className="red_clr">
                                <MdArrowDropDown className="b-c-t red_f_clr" />{" "}
                                &nbsp;
                                {parseFloat(gcs__change).toFixed(2)} %
                              </div>
                            )}
                          </>
                        ) : crypto24hchanges[res.project_api_name] !== null ? (
                          <a href={`/currencies/${res.project_api_name}`}>
                            {crypto24hchanges[res.project_api_name] >= 0 ? (
                              <div className="green_clr">
                                <MdArrowDropUp className="b-c-t green_f_clr" />{" "}
                                &nbsp;
                                {crypto24hchanges[res.project_api_name] !==
                                undefined ? (
                                  ` ${crypto24hchanges[
                                    res.project_api_name
                                  ].toFixed(2)} %`
                                ) : (
                                  <Skeleton className="w-7" />
                                )}
                              </div>
                            ) : (
                              <div className="red_clr">
                                <MdArrowDropDown className="b-c-t red_f_clr" />
                                &nbsp;
                                {crypto24hchanges[res.project_api_name] !==
                                undefined ? (
                                  `${crypto24hchanges[
                                    res.project_api_name
                                  ].toFixed(2)} %`
                                ) : (
                                  <Skeleton className="w-7" />
                                )}
                              </div>
                            )}
                          </a>
                        ) : (
                          "-------------"
                        )}
                      </div>
                    ))}
                  {sortedCrypt.length > 0 ? (
                    ""
                  ) : (
                    <>
                      <div className="d-f a-i-c j-c-s-b m-y-1">
                        <div className="">
                          <Skeleton className="w-7" />
                        </div>
                        <div className="green_clr">
                          <Skeleton className="w-7" />
                        </div>
                      </div>
                      <div className="d-f a-i-c j-c-s-b m-y-1">
                        <div className="">
                          <Skeleton className="w-7" />
                        </div>
                        <div className="green_clr">
                          <Skeleton className="w-7" />
                        </div>
                      </div>
                      <div className="d-f a-i-c j-c-s-b m-y-1">
                        <div className="">
                          <Skeleton className="w-7" />
                        </div>
                        <div className="green_clr">
                          <Skeleton className="w-7" />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-4 m-y-1">
                {" "}
                <div className="box_shadow p-x-1_5 p-y-0_5 b-r-10 h-100">
                  <div className="d-f a-i-c j-c-s-b m-t-1 m-b-2 ">
                    <div className="f-w-600 f-s-1_25">
                      {/* <BiTimeFive className="b-c-t f-s-2" /> */}
                      <img src={timer} className="w-2" />
                      &nbsp; Recently Added
                    </div>
                  </div>
                  {[
                    // Manually inserted item
                    {
                      status: "Approved",
                      project_api_name: "rusd",
                      link_logo:
                        "https://ribg.digital/wp-content/uploads/2025/01/rusd-150x150.png",
                      project_name: "RUSD",
                      project_ticker_symbol: "RUSD",
                      tick: "blue", // or "blue" or null
                    },
                    ...crypto_recently, // Spread the existing array
                  ]
                    .filter((res) => res.status === "Approved") // Filter only "Approved" items
                    .slice(0, 5) // Take the top 5 items (including the manually added one)
                    .map((res, index) => (
                      <div className="d-f a-i-c j-c-s-b m-y-1" key={index}>
                        <div className="">
                          <a href={`/currencies/${res.project_api_name}`}>
                            <img
                              src={res.link_logo}
                              className="w-2 h-2 b-r-50"
                            />
                            &nbsp; {res.project_name} &nbsp;
                            <span className="g_clr f-s-0_8 m-l-0_5">
                              {res.project_ticker_symbol}
                            </span>
                            &nbsp; &nbsp;
                            <Tooltip
                              title={`${
                                res.tick == "gold"
                                  ? "Sharia Compliant"
                                  : "Verified"
                              }`}
                            >
                              <span>
                                {res.tick ? (
                                  <button className="b-c-t b-n ">
                                    {res.tick == "gold" ? (
                                      <img src={tick_gold} className="w-1_5" />
                                    ) : (
                                      <img src={tick_blue} className="w-1_5" />
                                    )}
                                  </button>
                                ) : (
                                  ""
                                )}
                              </span>
                            </Tooltip>
                          </a>
                        </div>
                        {res.project_name === "RUSD" ? (
                          <a href={`/currencies/${res.project_api_name}`}>
                            {rusd_24HChange >= 0 ? (
                              <div className="green_clr">
                                <MdArrowDropUp className="b-c-t green_f_clr" />{" "}
                                &nbsp;
                                {rusd_24HChange !== undefined ? (
                                  `${parseFloat(rusd_24HChange).toFixed(2)} %`
                                ) : (
                                  <Skeleton className="w-7" />
                                )}
                              </div>
                            ) : (
                              <div className="red_clr">
                                <MdArrowDropDown className="b-c-t red_f_clr" />{" "}
                                &nbsp;
                                {rusd_24HChange !== undefined ? (
                                  `${parseFloat(rusd_24HChange).toFixed(2)} %`
                                ) : (
                                  <Skeleton className="w-7" />
                                )}
                              </div>
                            )}
                          </a>
                        ) : res.project_name === "XAUS" ? (
                          <>
                            {((parseFloat(xaus_prices) - h24_xaus) / h24_xaus) *
                              100 >=
                            0 ? (
                              <div className="green_clr">
                                <MdArrowDropUp className="b-c-t green_f_clr" />{" "}
                                &nbsp;
                                {(
                                  ((parseFloat(xaus_prices) - h24_xaus) /
                                    h24_xaus) *
                                  100
                                ).toFixed(2)}{" "}
                                &nbsp; %
                              </div>
                            ) : (
                              <div className="red_clr">
                                <MdArrowDropDown className="b-c-t red_f_clr" />{" "}
                                &nbsp;
                                {(
                                  ((parseFloat(xaus_prices) - h24_xaus) /
                                    h24_xaus) *
                                  100
                                ).toFixed(2)}{" "}
                                &nbsp; %
                              </div>
                            )}
                          </>
                        ) : res.project_name == "GCS" ? (
                          <>
                            {parseFloat(gcs__change) >= 0 ? (
                              <div className="green_clr">
                                <MdArrowDropUp className="b-c-t green_f_clr" />{" "}
                                &nbsp;
                                {parseFloat(gcs__change).toFixed(2)} %
                              </div>
                            ) : (
                              <div className="red_clr">
                                <MdArrowDropDown className="b-c-t red_f_clr" />{" "}
                                &nbsp;
                                {parseFloat(gcs__change).toFixed(2)} %
                              </div>
                            )}
                          </>
                        ) : crypto24hchanges[res.project_api_name] !== null ? (
                          <a href={`/currencies/${res.project_api_name}`}>
                            {crypto24hchanges[res.project_api_name] >= 0 ? (
                              <div className="green_clr">
                                <MdArrowDropUp className="b-c-t green_f_clr" />{" "}
                                &nbsp;
                                {crypto24hchanges[res.project_api_name] !==
                                undefined ? (
                                  ` ${crypto24hchanges[
                                    res.project_api_name
                                  ].toFixed(2)} %`
                                ) : (
                                  <Skeleton className="w-7" />
                                )}
                              </div>
                            ) : (
                              <div className="red_clr">
                                <MdArrowDropDown className="b-c-t red_f_clr" />
                                &nbsp;
                                {crypto24hchanges[res.project_api_name] !==
                                undefined ? (
                                  `${crypto24hchanges[
                                    res.project_api_name
                                  ].toFixed(2)} %`
                                ) : (
                                  <Skeleton className="w-7" />
                                )}
                              </div>
                            )}
                          </a>
                        ) : (
                          "-------------"
                        )}
                      </div>
                    ))}

                  {crypto_recently.length > 0 ? (
                    ""
                  ) : (
                    <>
                      <div className="d-f a-i-c j-c-s-b m-y-1">
                        <div className="">
                          <Skeleton className="w-7" />
                        </div>
                        <div className="green_clr">
                          <Skeleton className="w-7" />
                        </div>
                      </div>
                      <div className="d-f a-i-c j-c-s-b m-y-1">
                        <div className="">
                          <Skeleton className="w-7" />
                        </div>
                        <div className="green_clr">
                          <Skeleton className="w-7" />
                        </div>
                      </div>
                      <div className="d-f a-i-c j-c-s-b m-y-1">
                        <div className="">
                          <Skeleton className="w-7" />
                        </div>
                        <div className="green_clr">
                          <Skeleton className="w-7" />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-4 m-y-1 ">
                <div className="box_shadow p-x-1_5 p-y-0_5 b-r-10 h-100">
                  {crypto_recently.length > 0 ? (
                    <>
                      {" "}
                      <div className="f-s-1_25 f-w-600 m-t-1">
                        <img
                          src="https://img.icons8.com/3d-fluency/94/chart.png"
                          className="w-2"
                        />
                        &nbsp;Crypto Fear and Greed Indicator
                      </div>
                      <div className="t-a-c">
                        <div className="d-i-b m-a">
                          {" "}
                          <Pie_chart />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="f-s-1_25 f-w-600 m-t-1">
                        <img
                          src="https://img.icons8.com/3d-fluency/94/chart.png"
                          className="w-2"
                        />
                        &nbsp;Crypto Fear and Greed Indicator
                      </div>
                      <div className="t-a-c m-t-2">
                        <Skeleton className="w-7" />
                      </div>
                      <div className="t-a-c m-t-2 m-b-2">
                        <Skeleton className="w-15 h-5" />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="m-b-3">
            <Paper
              sx={{
                width: "100%",

                background: "transparent",
              }}
              className="b-s-n"
            >
              <TableContainer>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  className="b-c-t "
                >
                  <TableHead className="b-c-t c-p">
                    <TableRow className="b-c-t c-p ">
                      <TableCell align="left" style={{ minWidth: "170" }}>
                        #
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: "170" }}>
                        Name
                      </TableCell>
                      <TableCell
                        align="right"
                        className="w-s-n-w"
                        style={{ minWidth: "170" }}
                        onClick={() => {
                          togglePriceSort();
                          setPriceSortOrder(!priceSortOrder);
                        }}
                      >
                        Price
                        {priceSortOrder ? " ▲" : " ▼"}
                      </TableCell>
                      {/* <TableCell align="right" style={{ minWidth: "170" }}>
                        1h%
                      </TableCell> */}
                      <TableCell
                        align="right"
                        className="w-s-n-w"
                        style={{ minWidth: "170" }}
                        onClick={() => {
                          toggle24hSort();
                          setH24ChangeSortOrder(!h24ChangeSortOrder);
                        }}
                      >
                        24h %{h24ChangeSortOrder ? " ▲" : " ▼"}
                      </TableCell>
                      {/* <TableCell align="right" style={{ minWidth: "170" }}>
                        7d%
                      </TableCell> */}
                      <TableCell
                        align="right"
                        className="w-s-n-w"
                        style={{ minWidth: "170" }}
                        onClick={() => {
                          toggleMarketCapSort();
                          setMarketCapSortOrder(!marketCapSortOrder);
                        }}
                      >
                        Market Cap
                        {marketCapSortOrder ? " ▼" : " ▲"}
                      </TableCell>

                      <TableCell
                        align="right"
                        className="w-s-n-w"
                        style={{ minWidth: "170" }}
                        onClick={() => {
                          togglevolume24hSort();
                          setvolume24hChangeSortOrder(
                            !volume24hChangeSortOrder,
                          );
                        }}
                      >
                        Volume(24h)
                        {volume24hChangeSortOrder ? " ▲" : " ▼"}
                      </TableCell>
                      <TableCell
                        align="right"
                        className="w-s-n-w"
                        style={{ minWidth: "170" }}
                        onClick={() => {
                          toggleCirculatingSupplySort();
                          setCirculatingSupplySortOrder(
                            !circulatingSupplySortOrder,
                          );
                        }}
                      >
                        Circulating Supply
                        {circulatingSupplySortOrder ? " ▲" : " ▼"}
                      </TableCell>
                      <TableCell align="right" style={{ minWidth: "170" }}>
                        Last 7 Days
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody className="b-c-t">
                    {sortedCrypt.slice(0, visibleRows).map((res, index) => {
                      if (res.status === "Approved") {
                        const priceChange = marketPrices[res.project_api_name];
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            className="c-p"
                          >
                            <TableCell align="left" style={{ minWidth: "170" }}>
                              <a href={`/currencies/${res.project_api_name}`}>
                                {res.status == "Approved" ? index + 1 : ""}
                              </a>
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ minWidth: "170" }}
                              className="w-s-n-w"
                            >
                              <a href={`/currencies/${res.project_api_name}`}>
                                <img
                                  src={res.link_logo}
                                  className="w-2_5 h-2_5 b-r-50"
                                />
                                &nbsp; {res.project_name} &nbsp;
                                <span className="g_clr">
                                  {res.project_ticker_symbol}
                                </span>
                                &nbsp; &nbsp;
                                <Tooltip
                                  title={`${
                                    res.tick == "gold"
                                      ? "Sharia Compliant"
                                      : "Verified"
                                  }`}
                                >
                                  <span>
                                    {res.tick ? (
                                      <button className="b-c-t b-n ">
                                        {res.tick == "gold" ? (
                                          <img
                                            src={tick_gold}
                                            className="w-1_5 m-r-2"
                                          />
                                        ) : (
                                          <img
                                            src={tick_blue}
                                            className="w-1_5 m-r-2"
                                          />
                                        )}
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </Tooltip>
                              </a>
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{ minWidth: "170" }}
                              className="w-s-n-w"
                            >
                              {res.project_api_name == "GCS" ? (
                                <>
                                  {" "}
                                  ${" "}
                                  {gcs__price
                                    ? parseFloat(gcs__price).toFixed(2)
                                    : ""}
                                </>
                              ) : res.project_api_name == "XAUS" ? (
                                <>
                                  {" "}
                                  ${" "}
                                  {xaus_prices
                                    ? parseFloat(xaus_prices).toFixed(2)
                                    : ""}
                                </>
                              ) : cryptoPrices[res.project_api_name] !==
                                null ? (
                                <a href={`/currencies/${res.project_api_name}`}>
                                  {cryptoPrices[res.project_api_name] ? (
                                    `$ ${parseFloat(
                                      cryptoPrices[res.project_api_name],
                                    )
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                                  ) : (
                                    <Skeleton className="w-7" />
                                  )}
                                </a>
                              ) : (
                                "-------------"
                              )}
                            </TableCell>

                            <TableCell
                              align="right"
                              style={{ minWidth: "170" }}
                              className="w-s-n-w"
                            >
                              {res.project_name == "XAUS" ? (
                                <>
                                  {parseFloat(xaus_24HChange) >= 0 ? (
                                    <div className="green_clr">
                                      <MdArrowDropUp className="b-c-t green_f_clr" />{" "}
                                      &nbsp;
                                      {parseFloat(xaus_24HChange).toFixed(
                                        2,
                                      )}{" "}
                                      &nbsp; %
                                    </div>
                                  ) : (
                                    <div className="red_clr">
                                      <MdArrowDropDown className="b-c-t red_f_clr" />{" "}
                                      &nbsp;
                                      {parseFloat(xaus_24HChange).toFixed(2)} %
                                    </div>
                                  )}
                                </>
                              ) : res.project_name == "GCS" ? (
                                <>
                                  {parseFloat(gcs__change) >= 0 ? (
                                    <div className="green_clr">
                                      <MdArrowDropUp className="b-c-t green_f_clr" />{" "}
                                      &nbsp;
                                      {parseFloat(gcs__change).toFixed(2)} %
                                    </div>
                                  ) : (
                                    <div className="red_clr">
                                      <MdArrowDropDown className="b-c-t red_f_clr" />{" "}
                                      &nbsp;
                                      {parseFloat(gcs__change).toFixed(2)} %
                                    </div>
                                  )}
                                </>
                              ) : crypto24hchanges[res.project_api_name] !==
                                null ? (
                                <a href={`/currencies/${res.project_api_name}`}>
                                  {crypto24hchanges[res.project_api_name] >=
                                  0 ? (
                                    <div className="green_clr">
                                      <MdArrowDropUp className="b-c-t green_f_clr" />{" "}
                                      &nbsp;
                                      {crypto24hchanges[
                                        res.project_api_name
                                      ] !== undefined ? (
                                        ` ${crypto24hchanges[
                                          res.project_api_name
                                        ].toFixed(2)} %`
                                      ) : (
                                        <Skeleton className="w-7" />
                                      )}
                                    </div>
                                  ) : (
                                    <div className="red_clr">
                                      <MdArrowDropDown className="b-c-t red_f_clr" />
                                      &nbsp;
                                      {crypto24hchanges[
                                        res.project_api_name
                                      ] !== undefined ? (
                                        `${crypto24hchanges[
                                          res.project_api_name
                                        ].toFixed(2)} %`
                                      ) : (
                                        <Skeleton className="w-7" />
                                      )}
                                    </div>
                                  )}
                                </a>
                              ) : (
                                "-------------"
                              )}
                            </TableCell>
                            {/* <TableCell
                              align="rightr"
                              className="green_clr g_f_clr"
                            >
                              {" "}
                              <MdArrowDropUp className="b-c-t green_f_clr" />{" "}
                              11.07%
                            </TableCell> */}
                            <TableCell
                              align="right"
                              style={{ minWidth: "170" }}
                              className="w-s-n-w"
                            >
                              {res.project_name == "XAUS" ? (
                                <>
                                  ${" "}
                                  {(69500 * parseFloat(xaus_prices))
                                    .toFixed(0)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </>
                              ) : res.project_name == "GCS" ? (
                                <>
                                  ${" "}
                                  {(
                                    res.circulating_supply *
                                    parseFloat(gcs__price)
                                  )
                                    .toFixed(0)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </>
                              ) : res.project_name == "MAAL CHAIN" ? (
                                <>
                                  ${" "}
                                  {(
                                    Number(circ_supply_maal) *
                                    Number(cryptoPrices[res.project_api_name])
                                  )
                                    .toFixed(0)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </>
                              ) : marketPrices[res.project_api_name] !==
                                null ? (
                                <a href={`/currencies/${res.project_api_name}`}>
                                  {marketPrices[res.project_api_name] !==
                                  undefined ? (
                                    `$ ${marketPrices[res.project_api_name]
                                      .toFixed(0)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                                  ) : (
                                    <Skeleton className="w-7" />
                                  )}
                                </a>
                              ) : (
                                "-------------"
                              )}
                            </TableCell>

                            <TableCell
                              align="right"
                              style={{ minWidth: "170" }}
                              className="w-s-n-w"
                            >
                              {res.project_name === "XAUS" ? (
                                <>
                                  ${" "}
                                  {parseFloat(randomNumberxaus)
                                    .toFixed(0)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </>
                              ) : res.project_name === "GCS" ? (
                                <>
                                  ${" "}
                                  {parseFloat(gcs__volume)
                                    .toFixed(0)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </>
                              ) : volume[res.project_api_name] !== null ? (
                                <a href={`/currencies/${res.project_api_name}`}>
                                  {volume[res.project_api_name] !==
                                  undefined ? (
                                    `$ ${volume[res.project_api_name]
                                      .toFixed(0)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                                  ) : (
                                    <Skeleton className="w-7" />
                                  )}
                                </a>
                              ) : (
                                "-------------"
                              )}
                            </TableCell>

                            <TableCell
                              align="right"
                              style={{ minWidth: "170" }}
                              className="w-s-n-w"
                            >
                              <a href={`/currencies/${res.project_api_name}`}>
                                {res.project_name === "XAUS" ||
                                res.project_name === "GCS" ? (
                                  <>
                                    {parseFloat(res.circulating_supply)
                                      .toFixed(0)
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ",",
                                      )}{" "}
                                    &nbsp;
                                    {res.project_ticker_symbol}
                                  </>
                                ) : res.project_name == "MAAL CHAIN" ? (
                                  <>
                                    {Number(circ_supply_maal)
                                      .toFixed(0)
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ",",
                                      )}{" "}
                                    MAAL
                                  </>
                                ) : circulating_supply[res.project_api_name] !==
                                  null ? (
                                  <a
                                    href={`/currencies/${res.project_api_name}`}
                                  >
                                    {circulating_supply[
                                      res.project_api_name
                                    ] !== undefined ? (
                                      ` ${circulating_supply[
                                        res.project_api_name
                                      ]
                                        .toFixed(0)
                                        .replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ",",
                                        )}  
                                    ${res.project_ticker_symbol}`
                                    ) : (
                                      <Skeleton className="w-7" />
                                    )}
                                  </a>
                                ) : (
                                  "-------------"
                                )}
                              </a>
                            </TableCell>

                            <TableCell
                              align="right"
                              style={{ minWidth: "170" }}
                              className="w-s-n-w"
                            >
                              {" "}
                              <a href={`/currencies/${res.project_api_name}`}>
                                <div className="w-7 f-r">
                                  {/* <img src={graph} className="w-100" /> */}
                                  <Sparkline_chart
                                    api={res.graph_historical_api}
                                  />
                                </div>
                              </a>
                            </TableCell>
                          </TableRow>
                        );
                      }
                      return null;
                    })}
                    {sortedCrypt.slice(35, visibleRows).map((res, index) => {
                      if (res.status === "Approved") {
                        const priceChange = marketPrices[res.project_api_name];
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            className="c-p"
                          >
                            <TableCell align="left" style={{ minWidth: "170" }}>
                              <a
                                href={`https://www.dextools.io/app/en/bnb/pair-explorer/0x635e8ee8acb5077e236534ab128028340d158025`}
                              >
                                37
                              </a>
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ minWidth: "170" }}
                              className="w-s-n-w"
                            >
                              <a
                                href={`https://www.dextools.io/app/en/bnb/pair-explorer/0x635e8ee8acb5077e236534ab128028340d158025`}
                              >
                                <img
                                  src="https://ribg.digital/wp-content/uploads/2025/01/rusd-150x150.png"
                                  className="w-2_5 h-2_5 b-r-50"
                                />
                                &nbsp; RUSD &nbsp;
                                <span className="g_clr">RUSD</span>
                                &nbsp; &nbsp;
                                <Tooltip title={`Verified`}>
                                  <span>
                                    {res.tick ? (
                                      <button className="b-c-t b-n ">
                                        <img
                                          src={tick_blue}
                                          className="w-1_5 m-r-2"
                                        />
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </Tooltip>
                              </a>
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{ minWidth: "170" }}
                              className="w-s-n-w"
                            >
                              <a
                                href={`https://www.dextools.io/app/en/bnb/pair-explorer/0x635e8ee8acb5077e236534ab128028340d158025`}
                              >
                                {console.log(
                                  "Rendering price value:",
                                  rusd_price,
                                )}{" "}
                                {/* Debug log */}${" "}
                                {rusd_price !== undefined && rusd_price !== null
                                  ? Number(rusd_price).toLocaleString("en-US", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 6,
                                    })
                                  : "0.00"}
                              </a>
                            </TableCell>

                            <TableCell
                              align="right"
                              style={{ minWidth: "170" }}
                              className="w-s-n-w"
                            >
                              <a
                                href={`https://www.dextools.io/app/en/bnb/pair-explorer/0x635e8ee8acb5077e236534ab128028340d158025`}
                              >
                                {loading ? (
                                  <Skeleton className="w-7" />
                                ) : rusd_24HChange >= 0 ? (
                                  <div className="green_clr">
                                    <MdArrowDropUp className="b-c-t green_f_clr" />{" "}
                                    &nbsp;
                                    {rusd_24HChange !== undefined ? (
                                      `${Number(rusd_24HChange).toFixed(2)} %`
                                    ) : (
                                      <Skeleton className="w-7" />
                                    )}
                                  </div>
                                ) : (
                                  <div className="red_clr">
                                    <MdArrowDropDown className="b-c-t red_f_clr" />
                                    &nbsp;
                                    {rusd_24HChange !== undefined ? (
                                      `${Number(rusd_24HChange).toFixed(2)} %`
                                    ) : (
                                      <Skeleton className="w-7" />
                                    )}
                                  </div>
                                )}
                              </a>
                            </TableCell>
                            {/* <TableCell
                              align="rightr"
                              className="green_clr g_f_clr"
                            >
                              {" "}
                              <MdArrowDropUp className="b-c-t green_f_clr" />{" "}
                              11.07%
                            </TableCell> */}
                            <TableCell
                              align="right"
                              style={{ minWidth: "170" }}
                              className="w-s-n-w"
                            >
                              <a
                                href={`https://www.dextools.io/app/en/bnb/pair-explorer/0x635e8ee8acb5077e236534ab128028340d158025`}
                              >
                                {/* Debug log */}${" "}
                                {rusd_mcap !== undefined && rusd_mcap !== null
                                  ? Number(rusd_mcap).toLocaleString("en-US", {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 2,
                                    })
                                  : "0.00"}
                              </a>
                            </TableCell>

                            <TableCell
                              align="right"
                              style={{ minWidth: "170" }}
                              className="w-s-n-w"
                            >
                              <a
                                href={`https://www.dextools.io/app/en/bnb/pair-explorer/0x635e8ee8acb5077e236534ab128028340d158025`}
                              >
                                {/* Debug log */}${" "}
                                {rusd_vol !== undefined && rusd_vol !== null
                                  ? Number(rusd_vol).toLocaleString("en-US", {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 2,
                                    })
                                  : "0.00"}
                              </a>
                            </TableCell>

                            <TableCell
                              align="right"
                              style={{ minWidth: "170" }}
                              className="w-s-n-w"
                            >
                              <a
                                href={`https://www.dextools.io/app/en/bnb/pair-explorer/0x635e8ee8acb5077e236534ab128028340d158025`}
                              >
                                {/* Debug log */}
                                {rusd_supply !== undefined &&
                                rusd_supply !== null
                                  ? Number(rusd_supply).toLocaleString(
                                      "en-US",
                                      {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 2,
                                      },
                                    ) + " RUSD"
                                  : "0.00"}
                              </a>
                            </TableCell>

                            <TableCell
                              align="right"
                              style={{ minWidth: "170" }}
                              className="w-s-n-w"
                            >
                              {" "}
                              <a href={`/currencies/${res.project_api_name}`}>
                                <div className="w-7 f-r">
                                  {/* <img src={graph} className="w-100" /> */}
                                  <Sparkline_chart
                                    api={res.graph_historical_api}
                                  />
                                </div>
                              </a>
                            </TableCell>
                          </TableRow>
                        );
                      }
                      return null;
                    })}
                    {crypt.length > 0 ? (
                      ""
                    ) : (
                      <>
                        <TableRow hover role="checkbox" tabIndex={-1}>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          {/* <TableCell
                        align="center"
                        className="green_clr g_f_clr"
                      >
                        <Skeleton />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton />
                      </TableCell>
                      <TableCell
                        align="center"
                        className="green_clr g_f_clr"
                      >
                        <Skeleton />
                      </TableCell> */}
                          <TableCell align="center">
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="">
                            {" "}
                            <Skeleton />
                          </TableCell>
                        </TableRow>
                        <TableRow hover role="checkbox" tabIndex={-1}>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          {/* <TableCell
                      align="center"
                      className="green_clr g_f_clr"
                    >
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell
                      align="center"
                      className="green_clr g_f_clr"
                    >
                      <Skeleton />
                    </TableCell> */}
                          <TableCell align="center">
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="">
                            {" "}
                            <Skeleton />
                          </TableCell>
                        </TableRow>
                        <TableRow hover role="checkbox" tabIndex={-1}>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          {/* <TableCell
                    align="center"
                    className="green_clr g_f_clr"
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    align="center"
                    className="green_clr g_f_clr"
                  >
                    <Skeleton />
                  </TableCell> */}
                          <TableCell align="center">
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="">
                            {" "}
                            <Skeleton />
                          </TableCell>
                        </TableRow>
                        <TableRow hover role="checkbox" tabIndex={-1}>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          {/* <TableCell
                  align="center"
                  className="green_clr g_f_clr"
                >
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell
                  align="center"
                  className="green_clr g_f_clr"
                >
                  <Skeleton />
                </TableCell> */}
                          <TableCell align="center">
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="">
                            {" "}
                            <Skeleton />
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                    {crypt.length > 0 ? (
                      ""
                    ) : (
                      <>
                        <TableRow hover role="checkbox" tabIndex={-1}>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          {/* <TableCell
                        align="center"
                        className="green_clr g_f_clr"
                      >
                        <Skeleton />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton />
                      </TableCell>
                      <TableCell
                        align="center"
                        className="green_clr g_f_clr"
                      >
                        <Skeleton />
                      </TableCell> */}
                          <TableCell align="center">
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="">
                            {" "}
                            <Skeleton />
                          </TableCell>
                        </TableRow>
                        <TableRow hover role="checkbox" tabIndex={-1}>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          {/* <TableCell
                      align="center"
                      className="green_clr g_f_clr"
                    >
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell
                      align="center"
                      className="green_clr g_f_clr"
                    >
                      <Skeleton />
                    </TableCell> */}
                          <TableCell align="center">
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="">
                            {" "}
                            <Skeleton />
                          </TableCell>
                        </TableRow>
                        <TableRow hover role="checkbox" tabIndex={-1}>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          {/* <TableCell
                    align="center"
                    className="green_clr g_f_clr"
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    align="center"
                    className="green_clr g_f_clr"
                  >
                    <Skeleton />
                  </TableCell> */}
                          <TableCell align="center">
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="">
                            {" "}
                            <Skeleton />
                          </TableCell>
                        </TableRow>
                        <TableRow hover role="checkbox" tabIndex={-1}>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          {/* <TableCell
                  align="center"
                  className="green_clr g_f_clr"
                >
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell
                  align="center"
                  className="green_clr g_f_clr"
                >
                  <Skeleton />
                </TableCell> */}
                          <TableCell align="center">
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="">
                            {" "}
                            <Skeleton />
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
                {/*<Table*/}
                {/*  stickyHeader*/}
                {/*  aria-label="sticky table"*/}
                {/*  className="b-c-t "*/}
                {/*>*/}
                {/*  <TableBody className="b-c-t">*/}
                {/*    {sortedCrypt.slice(35, visibleRows).map((res, index) => {*/}
                {/*      if (res.status === "Approved") {*/}
                {/*        const priceChange = marketPrices[res.project_api_name];*/}
                {/*        return (*/}
                {/*          <TableRow*/}
                {/*            hover*/}
                {/*            role="checkbox"*/}
                {/*            tabIndex={-1}*/}
                {/*            className="c-p"*/}
                {/*          >*/}
                {/*            <TableCell align="left" style={{ minWidth: "170" }}>*/}
                {/*              <a*/}
                {/*                href={`https://www.dextools.io/app/en/bnb/pair-explorer/0x30290969ee5c3f6a46142bb1fad662fc755e753b?t=1736488101180`}*/}
                {/*              >*/}
                {/*                37*/}
                {/*              </a>*/}
                {/*            </TableCell>*/}
                {/*            <TableCell*/}
                {/*              align="left"*/}
                {/*              style={{ minWidth: "170" }}*/}
                {/*              className="w-s-n-w"*/}
                {/*            >*/}
                {/*              <a*/}
                {/*                href={`https://www.dextools.io/app/en/bnb/pair-explorer/0x30290969ee5c3f6a46142bb1fad662fc755e753b?t=1736488101180`}*/}
                {/*              >*/}
                {/*                <img*/}
                {/*                  src="https://sparkling-cranachan-23cc37.netlify.app/rusd-token-logo-transparent.png"*/}
                {/*                  className="w-2_5 h-2_5 b-r-50"*/}
                {/*                />*/}
                {/*                &nbsp; RUSD &nbsp;*/}
                {/*                <span className="g_clr">RUSD</span>*/}
                {/*                &nbsp; &nbsp;*/}
                {/*                <Tooltip*/}
                {/*                  title={`${*/}
                {/*                    res.tick == "gold"*/}
                {/*                      ? "Sharia Compliant"*/}
                {/*                      : "Verified"*/}
                {/*                  }`}*/}
                {/*                >*/}
                {/*                  <span>*/}
                {/*                    {res.tick ? (*/}
                {/*                      <button className="b-c-t b-n ">*/}
                {/*                        {res.tick == "gold" ? (*/}
                {/*                          <img*/}
                {/*                            src={tick_gold}*/}
                {/*                            className="w-1_5 m-r-2"*/}
                {/*                          />*/}
                {/*                        ) : (*/}
                {/*                          <img*/}
                {/*                            src={tick_blue}*/}
                {/*                            className="w-1_5 m-r-2"*/}
                {/*                          />*/}
                {/*                        )}*/}
                {/*                      </button>*/}
                {/*                    ) : (*/}
                {/*                      ""*/}
                {/*                    )}*/}
                {/*                  </span>*/}
                {/*                </Tooltip>*/}
                {/*              </a>*/}
                {/*            </TableCell>*/}
                {/*            <TableCell*/}
                {/*              align="right"*/}
                {/*              style={{ minWidth: "170" }}*/}
                {/*              className="w-s-n-w"*/}
                {/*            >*/}
                {/*              <a*/}
                {/*                href={`https://www.dextools.io/app/en/bnb/pair-explorer/0x30290969ee5c3f6a46142bb1fad662fc755e753b?t=1737002466156`}*/}
                {/*              >*/}
                {/*                {console.log(*/}
                {/*                  "Rendering price value:",*/}
                {/*                  rusd_price,*/}
                {/*                )}{" "}*/}
                {/*                /!* Debug log *!/${" "}*/}
                {/*                {rusd_price !== undefined && rusd_price !== null*/}
                {/*                  ? Number(rusd_price).toLocaleString("en-US", {*/}
                {/*                      minimumFractionDigits: 2,*/}
                {/*                      maximumFractionDigits: 6,*/}
                {/*                    })*/}
                {/*                  : "0.00"}*/}
                {/*              </a>*/}
                {/*            </TableCell>*/}

                {/*            <TableCell*/}
                {/*              align="right"*/}
                {/*              style={{ minWidth: "170" }}*/}
                {/*              className="w-s-n-w"*/}
                {/*            >*/}
                {/*              <a*/}
                {/*                href={`https://www.dextools.io/app/en/bnb/pair-explorer/0x30290969ee5c3f6a46142bb1fad662fc755e753b?t=1737002466156`}*/}
                {/*              >*/}
                {/*                {loading ? (*/}
                {/*                  <Skeleton className="w-7" />*/}
                {/*                ) : rusd_24HChange >= 0 ? (*/}
                {/*                  <div className="green_clr">*/}
                {/*                    <MdArrowDropUp className="b-c-t green_f_clr" />{" "}*/}
                {/*                    &nbsp;*/}
                {/*                    {rusd_24HChange !== undefined ? (*/}
                {/*                      `${Number(rusd_24HChange).toFixed(2)} %`*/}
                {/*                    ) : (*/}
                {/*                      <Skeleton className="w-7" />*/}
                {/*                    )}*/}
                {/*                  </div>*/}
                {/*                ) : (*/}
                {/*                  <div className="red_clr">*/}
                {/*                    <MdArrowDropDown className="b-c-t red_f_clr" />*/}
                {/*                    &nbsp;*/}
                {/*                    {rusd_24HChange !== undefined ? (*/}
                {/*                      `${Number(rusd_24HChange).toFixed(2)} %`*/}
                {/*                    ) : (*/}
                {/*                      <Skeleton className="w-7" />*/}
                {/*                    )}*/}
                {/*                  </div>*/}
                {/*                )}*/}
                {/*              </a>*/}
                {/*            </TableCell>*/}
                {/*            /!* <TableCell*/}
                {/*              align="rightr"*/}
                {/*              className="green_clr g_f_clr"*/}
                {/*            >*/}
                {/*              {" "}*/}
                {/*              <MdArrowDropUp className="b-c-t green_f_clr" />{" "}*/}
                {/*              11.07%*/}
                {/*            </TableCell> *!/*/}
                {/*            <TableCell*/}
                {/*              align="right"*/}
                {/*              style={{ minWidth: "170" }}*/}
                {/*              className="w-s-n-w"*/}
                {/*            >*/}
                {/*              <a*/}
                {/*                href={`https://www.dextools.io/app/en/bnb/pair-explorer/0x30290969ee5c3f6a46142bb1fad662fc755e753b?t=1737002466156`}*/}
                {/*              >*/}
                {/*                {console.log(*/}
                {/*                  "Rendering price value:",*/}
                {/*                  rusd_mcap,*/}
                {/*                )}{" "}*/}
                {/*                /!* Debug log *!/${" "}*/}
                {/*                {rusd_mcap !== undefined && rusd_mcap !== null*/}
                {/*                  ? Number(rusd_mcap).toLocaleString("en-US", {*/}
                {/*                      minimumFractionDigits: 0,*/}
                {/*                      maximumFractionDigits: 2,*/}
                {/*                    })*/}
                {/*                  : "0.00"}*/}
                {/*              </a>*/}
                {/*            </TableCell>*/}

                {/*            <TableCell*/}
                {/*              align="right"*/}
                {/*              style={{ minWidth: "170" }}*/}
                {/*              className="w-s-n-w"*/}
                {/*            >*/}
                {/*              <a*/}
                {/*                href={`https://www.dextools.io/app/en/bnb/pair-explorer/0x30290969ee5c3f6a46142bb1fad662fc755e753b?t=1737002466156`}*/}
                {/*              >*/}
                {/*                {console.log(*/}
                {/*                  "Rendering price value:",*/}
                {/*                  rusd_vol,*/}
                {/*                )}{" "}*/}
                {/*                /!* Debug log *!/${" "}*/}
                {/*                {rusd_vol !== undefined && rusd_vol !== null*/}
                {/*                  ? Number(rusd_vol).toLocaleString("en-US", {*/}
                {/*                      minimumFractionDigits: 0,*/}
                {/*                      maximumFractionDigits: 2,*/}
                {/*                    })*/}
                {/*                  : "0.00"}*/}
                {/*              </a>*/}
                {/*            </TableCell>*/}

                {/*            <TableCell*/}
                {/*              align="right"*/}
                {/*              style={{ minWidth: "170" }}*/}
                {/*              className="w-s-n-w"*/}
                {/*            >*/}
                {/*              <a*/}
                {/*                href={`https://www.dextools.io/app/en/bnb/pair-explorer/0x30290969ee5c3f6a46142bb1fad662fc755e753b?t=1737002466156`}*/}
                {/*              >*/}
                {/*                {console.log(*/}
                {/*                  "Rendering price value:",*/}
                {/*                  rusd_supply,*/}
                {/*                )}{" "}*/}
                {/*                /!* Debug log *!/${" "}*/}
                {/*                {rusd_supply !== undefined &&*/}
                {/*                rusd_supply !== null*/}
                {/*                  ? Number(rusd_supply).toLocaleString(*/}
                {/*                      "en-US",*/}
                {/*                      {*/}
                {/*                        minimumFractionDigits: 0,*/}
                {/*                        maximumFractionDigits: 2,*/}
                {/*                      },*/}
                {/*                    )*/}
                {/*                  : "0.00"}*/}
                {/*              </a>*/}
                {/*            </TableCell>*/}

                {/*            <TableCell*/}
                {/*              align="right"*/}
                {/*              style={{ minWidth: "170" }}*/}
                {/*              className="w-s-n-w"*/}
                {/*            >*/}
                {/*              {" "}*/}
                {/*              <a href={`/currencies/${res.project_api_name}`}>*/}
                {/*                <div className="w-7 f-r">*/}
                {/*                  /!* <img src={graph} className="w-100" /> *!/*/}
                {/*                  <Sparkline_chart*/}
                {/*                    api={res.graph_historical_api}*/}
                {/*                  />*/}
                {/*                </div>*/}
                {/*              </a>*/}
                {/*            </TableCell>*/}
                {/*          </TableRow>*/}
                {/*        );*/}
                {/*      }*/}
                {/*      return null;*/}
                {/*    })}*/}
                {/*    {crypt.length > 0 ? (*/}
                {/*      ""*/}
                {/*    ) : (*/}
                {/*      <>*/}
                {/*        <TableRow hover role="checkbox" tabIndex={-1}>*/}
                {/*          <TableCell align="center">*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          <TableCell align="center">*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          <TableCell align="center">*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          <TableCell align="center">*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          /!* <TableCell*/}
                {/*        align="center"*/}
                {/*        className="green_clr g_f_clr"*/}
                {/*      >*/}
                {/*        <Skeleton />*/}
                {/*      </TableCell>*/}
                {/*      <TableCell align="center">*/}
                {/*        <Skeleton />*/}
                {/*      </TableCell>*/}
                {/*      <TableCell*/}
                {/*        align="center"*/}
                {/*        className="green_clr g_f_clr"*/}
                {/*      >*/}
                {/*        <Skeleton />*/}
                {/*      </TableCell> *!/*/}
                {/*          <TableCell align="center">*/}
                {/*            {" "}*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          <TableCell align="center">*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          <TableCell align="center">*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          <TableCell align="">*/}
                {/*            {" "}*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*        </TableRow>*/}
                {/*        <TableRow hover role="checkbox" tabIndex={-1}>*/}
                {/*          <TableCell align="center">*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          <TableCell align="center">*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          <TableCell align="center">*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          <TableCell align="center">*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          /!* <TableCell*/}
                {/*      align="center"*/}
                {/*      className="green_clr g_f_clr"*/}
                {/*    >*/}
                {/*      <Skeleton />*/}
                {/*    </TableCell>*/}
                {/*    <TableCell align="center">*/}
                {/*      <Skeleton />*/}
                {/*    </TableCell>*/}
                {/*    <TableCell*/}
                {/*      align="center"*/}
                {/*      className="green_clr g_f_clr"*/}
                {/*    >*/}
                {/*      <Skeleton />*/}
                {/*    </TableCell> *!/*/}
                {/*          <TableCell align="center">*/}
                {/*            {" "}*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          <TableCell align="center">*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          <TableCell align="center">*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          <TableCell align="">*/}
                {/*            {" "}*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*        </TableRow>*/}
                {/*        <TableRow hover role="checkbox" tabIndex={-1}>*/}
                {/*          <TableCell align="center">*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          <TableCell align="center">*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          <TableCell align="center">*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          <TableCell align="center">*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          /!* <TableCell*/}
                {/*    align="center"*/}
                {/*    className="green_clr g_f_clr"*/}
                {/*  >*/}
                {/*    <Skeleton />*/}
                {/*  </TableCell>*/}
                {/*  <TableCell align="center">*/}
                {/*    <Skeleton />*/}
                {/*  </TableCell>*/}
                {/*  <TableCell*/}
                {/*    align="center"*/}
                {/*    className="green_clr g_f_clr"*/}
                {/*  >*/}
                {/*    <Skeleton />*/}
                {/*  </TableCell> *!/*/}
                {/*          <TableCell align="center">*/}
                {/*            {" "}*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          <TableCell align="center">*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          <TableCell align="center">*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          <TableCell align="">*/}
                {/*            {" "}*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*        </TableRow>*/}
                {/*        <TableRow hover role="checkbox" tabIndex={-1}>*/}
                {/*          <TableCell align="center">*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          <TableCell align="center">*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          <TableCell align="center">*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          <TableCell align="center">*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          /!* <TableCell*/}
                {/*  align="center"*/}
                {/*  className="green_clr g_f_clr"*/}
                {/*>*/}
                {/*  <Skeleton />*/}
                {/*</TableCell>*/}
                {/*<TableCell align="center">*/}
                {/*  <Skeleton />*/}
                {/*</TableCell>*/}
                {/*<TableCell*/}
                {/*  align="center"*/}
                {/*  className="green_clr g_f_clr"*/}
                {/*>*/}
                {/*  <Skeleton />*/}
                {/*</TableCell> *!/*/}
                {/*          <TableCell align="center">*/}
                {/*            {" "}*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          <TableCell align="center">*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          <TableCell align="center">*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*          <TableCell align="">*/}
                {/*            {" "}*/}
                {/*            <Skeleton />*/}
                {/*          </TableCell>*/}
                {/*        </TableRow>*/}
                {/*      </>*/}
                {/*    )}*/}
                {/*  </TableBody>*/}
                {/*</Table>*/}
              </TableContainer>
              <div ref={tableRef}></div>
            </Paper>
          </div>
        </div>
        <Footer
          Dissconnect={Dissconnect}
          Metamask={Metamask}
          WalletC={WalletC}
        />
      </SkeletonTheme>
      <Toaster position="top-center" reverseOrder={true} />
    </>
  );
}

export default Home;
